import { ref } from "vue";

export default function() {
    const testSystems = ['DEV', 'LOCAL'];

    const environment = ref(process.env.VUE_APP_ENV);

    const isTestSystem = () => {
        return testSystems.includes( environment.value );
    }

    const isLocalSystem = () => {
        return environment.value === 'LOCAL';
    }

    const isDevSystem = () => {
        return environment.value === 'DEV';
    }

    const isStagingSystem = () => {
        return environment.value === 'STAGING';
    }

    const isProductionSystem = () => {
        return environment.value === 'PRODUCTION';
    }

    return {
        environment,
        isTestSystem,
        isLocalSystem,
        isDevSystem,
        isStagingSystem,
        isProductionSystem
    }
}